import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import { Link } from "gatsby"

import Seo from "../components/seo"
import Layout from "../components/layout"
import ImageLoader from "../components/image-loader"
import { borders, colors, shadows } from "../styles"

const PostContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    gap: 3rem;
    border-radius: 0.5rem;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  border: ${borders.standard};
  border-radius: 0;
  box-shadow: ${shadows.standard};
  overflow: hidden;

  @media screen and (min-width: 768px) {
    border-radius: 0.5rem;
  }
`

const ContentContainer = styled.div`
  padding: 1.5rem;
  border-radius: 0rem;
  border: ${borders.standard};
  box-shadow: ${shadows.standard};
  background: ${colors.darkGrey};
  overflow: hidden;

  @media screen and (min-width: 768px) {
    border-radius: 0.5rem;
  }
`

const PostDetails = styled.div`
  display: flex;
  font-size: 0.8rem;
  font-style: italic;

  & span {
    padding: 0 0.5rem;
    border-right: solid 1px rgba(255, 255, 255, 0.5);
  }

  & span:first-of-type {
    padding-left: 0;
  }

  & span:last-of-type {
    padding-right: 0;
    border-right: none;
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;

    & span {
      padding: 0;
      border-right: none;
    }
  }
`

const PostsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  min-height: 40rem;
  margin: 0 5rem;

  /* Flip content and image on every second row */
  @media only screen and (min-width: 768px) {
    & ${PostContainer}:nth-of-type(even) {
      & ${ContentContainer}:nth-of-type(even) {
        order: -1;
      }
    }
  }

  @media only screen and (max-width: 1439px) {
    margin: 0 1rem;
  }

  @media only screen and (max-width: 767px) {
    margin: 0;
  }
`

const BlogPage = ({ data }) => {
  const posts = data.blogPost.edges
  return (
    <Layout>
      <Seo
        title="Blog | Sing Smart Studios | Townsville Singing School"
        keywords={[`blog`, `facebook`]}
      />
      <PostsContainer>
        {posts.map(({ node: post }) => {
          return (
            <PostContainer to={post.fields.slug} key={post.fields.slug}>
              <ImageContainer>
                <ImageLoader
                  alt={post.frontmatter.title}
                  src={post.frontmatter.thumbnail}
                  objectFit="cover"
                />
              </ImageContainer>
              <ContentContainer>
                <h3>{post.frontmatter.title}</h3>
                <p>{post.excerpt}</p>
                <PostDetails>
                  <span>{post.frontmatter.date}</span>
                  <span>{post.timeToRead}min reading time</span>
                  <span>{post.wordCount.words} words</span>
                </PostDetails>
              </ContentContainer>
            </PostContainer>
          )
        })}
      </PostsContainer>
    </Layout>
  )
}

export const query = graphql`
  query {
    blogPost: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/markdown/blog/" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "D MMM YY")
            thumbnail
          }
          excerpt
          timeToRead
          wordCount {
            words
          }
        }
      }
    }
  }
`

export default BlogPage
